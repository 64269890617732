<script setup>
import { computed } from 'vue';
const props = defineProps({
  artifactId: { type: String, default: '' },
  payload: { type: String, default: null },
  isDownloaded: { type: Boolean, default: false },
  artifactData: { type: Object, default: () => ({}) },
  fullScreen: { type: Boolean, default: false },
  isDownloading: { type: Boolean, default: false },
})
const videoPayload = computed(() => {
  if (!props.payload) return null
  if (!Array.isArray(props.payload)) return props.payload
  return props.payload[Math.max(...Object.keys(props.payload).map(key => parseInt(key)))]
})
</script>

<template>
  <video
    v-if="videoPayload"
    :autoplay="true"
    :muted="true"
    :controls="true"
    :loop="true"
    :src="videoPayload"
    style="max-width: 100%"
  />
  <div 
    v-if="!props.isDownloaded && !videoPayload"
    class="d-flex flex-row align-center justify-center ga-4 rounded-lg border-md"
  >
    <div
      v-if="props.isDownloading"
      class="py-8"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </div>
    <div
      v-else
      class="py-4 d-flex flex-row align-center justify-center ga-4"
    >
      <v-icon
        icon="mdi-video"
        size="72"
      />
      <div>
        <v-list
          density="compact" 
        >
          <v-list-item
            :title="$t('artifact.properties.filesize')"
            :subtitle="filesize || $t('general_interface.unknown')"
            prepend-icon="mdi-harddisk"
          />
          <v-list-item
            :title="$t('artifact.properties.filename')"
            :subtitle="artifactData.preferredFilename"
            prepend-icon="mdi-form-textbox"
          />
          <v-list-item
            :title="$t('artifact.properties.checksum')"
            :subtitle="artifactData.md5"
            prepend-icon="mdi-barcode"
          />
        </v-list>
      </div>
    </div>
  </div>
  
  <div 
    v-if="props.isDownloaded && !videoPayload"
    class="px-6 py-8 d-flex flex-row align-center ga-2 justify-center rounded-lg border-md"
  >
    <v-icon
      icon="mdi-alert-circle-outline"
    />
    <p
      class="font-italic"
    >
      {{ $t('filters.artifacts.couldnt_download') }}
    </p>
  </div>
</template>

<style scoped>

</style>
