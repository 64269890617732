<script setup>
import { computed } from 'vue';
import { humanReadableFilesize } from '@/composables/filesizeHelpers';

const props = defineProps({
  artifactId: { type: String, default: '' },
  payload: { type: String, default: null },
  isDownloaded: { type: Boolean, default: false },
  artifactData: { type: Object, default: () => ({}) },
  fullScreen: { type: Boolean, default: false },
  isDownloading: { type: Boolean, default: false },
})

const filesize = computed(() => {
  if (!props.artifactData || !props.artifactData.size) return null
  return humanReadableFilesize(props.artifactData.size)
})
</script>

<template>
  <div class="py-4 d-flex flex-row align-center justify-center ga-4 rounded-lg border-md">
    <v-icon
      icon="mdi-file-multiple"
      size="72"
    />
    <div>
      <v-list
        density="compact" 
      >
        <v-list-item
          :title="$t('artifact.properties.filesize')"
          :subtitle="filesize || $t('general_interface.unknown')"
          prepend-icon="mdi-harddisk"
        />
        <v-list-item
          :title="$t('artifact.properties.filename')"
          :subtitle="artifactData.preferredFilename"
          prepend-icon="mdi-form-textbox"
        />
        <v-list-item
          :title="$t('artifact.properties.checksum')"
          :subtitle="artifactData.md5"
          prepend-icon="mdi-barcode"
        />
      </v-list>
    </div>
  </div>
</template>

<style scoped>

</style>
