<script setup>
import { computed, onMounted } from 'vue';
import { useCamerasStore } from '@/store/cameras';

const cameraStore = useCamerasStore()

const props = defineProps({
  artifactId: { type: String, default: '' },
  payload: { type: Object, default: null },
  isDownloaded: { type: Boolean, default: false },
  artifactData: { type: Object, default: () => ({}) },
  fullScreen: { type: Boolean, default: false },
  isDownloading: { type: Boolean, default: false },
})

const stillFrame = computed(() => {
  if (!cameraStore.currentStillFrames[props.artifactData.data.cameraId]) return null
  if (!cameraStore.currentStillFrames[props.artifactData.data.cameraId][200]) return null
  return cameraStore.currentStillFrames[props.artifactData.data.cameraId][200].frame
})

const imagePayload = computed(() => {
  if (!props.payload) return null
  if (typeof props.payload === 'string') return props.payload
  if (typeof props.payload === 'object' && !Array.isArray(props.payload))
    return props.payload[Object.keys(props.payload)[0]]

  return props.payload[Math.max(...Object.keys(props.payload).map(key => parseInt(key)))]
})

onMounted(async () => {
  if (props.artifactData.data.cameraId)
    await cameraStore.loadCurrentStillFrame(props.artifactData.data.cameraId, { resizeY: 200 })
})
</script>

<template>
  <v-card
    variant="flat"
    elevation="0"
  >
    <v-img
      v-if="!isDownloaded"
      :src="stillFrame"
      :max-height="fullScreen ? '80vh' : '40vh'"
      gradient="to top right, rgba(0,0,0,0.6), rgba(0,0,0,.3)"
    >
      <v-container class="justify-center align-center fill-height">
        <v-chip prepend-icon="mdi-alert">
          {{ $t("artifact.not_downloaded_yet") }}
        </v-chip>
      </v-container>
    </v-img>
    <v-img
      v-else
      :src="imagePayload"
    />
  </v-card>
</template>

<style scoped>

</style>
