<script setup>
import {
  humanReadableTimestamp,
  humanReadableDuration,
  parseDuration,
} from '@/composables/datetime';
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { useCameraName } from '@/composables/cameraHelpers';
import ArtifactPayload from '@/components/artifacts/ArtifactPayload.vue';
import { useArtifactStore } from '@/store/artifacts';
import { humanReadableFilesize } from '@/composables/filesizeHelpers.js';
import { useInstanceStore } from '@/store/backendInstances';
import ShareButton from '@/components/ShareButton.vue';
import { writeToClipboard } from '@/composables/clipboard';
import { useToast } from 'vue-toastification';
import { useDisplay, useTheme } from 'vuetify';

const props = defineProps({ artifactId: { type: String, default: '' } });

const artifactStore = useArtifactStore();
const instanceStore = useInstanceStore();
const theme = useTheme();
const toast = useToast();
const { t } = useI18n({});
const { width } = useDisplay();

const fullscreenPayload = ref(false);

const isBelow1200px = computed(() => width.value < 1200);

const artifact = computed(() => {
  if (!artifactStore.artifacts[props.artifactId]) return null;
  return artifactStore.artifacts[props.artifactId].data;
});
const controlRecord = computed(() => {
  if (!artifactStore.artifacts[props.artifactId]) return null;
  return artifactStore.artifacts[props.artifactId].controlRecord;
});

const artifactTitle = computed(() => {
  return `${t(
    'artifact.type.' + artifact.value.type,
  )}, ${humanReadableTimestamp(artifact.value.timestamp)}`;
});

const hasDuration = computed(() => {
  if (!artifact.value) return false;
  if (!artifact.value.data) return false;
  if (artifact.value.data.time) return true;
  return !!artifact.value.data.durationSeconds;
});

const humanReadableTime = computed(() => {
  if (!hasDuration.value) return null;
  let start;
  let end;
  let duration;
  if (artifact.value.data.time) {
    start = artifact.value.data.time.start;
    end = artifact.value.data.time.end;
    duration = parseDuration(artifact.value.data.time.duration);

    if (end === undefined) end = artifact.value.timestamp;
    if (start === undefined) start = end - duration;
    if (duration === undefined) duration = end - start;
  } else {
    duration = artifact.value.data.durationSeconds * 1000;
    end = artifact.value.timestamp;
    start = end - duration;
  }
  return `${humanReadableTimestamp(start)} - ${humanReadableTimestamp(
    end,
  )} (${humanReadableDuration(duration)})`;
});

const isDownloaded = computed(() => {
  if (!controlRecord.value) return false;
  return controlRecord.value.downloaded;
});

const isDownloading = computed(() => {
  if (!controlRecord.value) return false;
  return controlRecord.value.downloading;
});

const shareUrl = computed(() => {
  return `${window.location.origin}/instance/${instanceStore.selectedInstanceId}/artifact/${props.artifactId}`;
});

async function downloadPayload() {
  await artifactStore.downloadArtifactPayload(props.artifactId);
}

async function savePayload() {
  if (!isDownloaded.value) return;
  artifactStore.savePayload(props.artifactId);
}

async function downloadAndSavePayload() {
  await downloadPayload();
  await savePayload();
}

async function copyComponentId(e) {
  const copyResult = writeToClipboard(props.artifactId);
  if (copyResult) {
    toast.success(t('toast.copied_to_clipboard'));
  } else {
    toast.error(t('toast.copy_failed'));
  }
  e.stopPropagation();
}
</script>

<template>
  <v-layout-card
    v-if="artifact"
    elevation="8"
  >
    <div
      class="d-flex flex-row justify-space-between align-center flex-wrap pa-2"
    >
      <v-card-title class="text-wrap">
        {{ artifactTitle }}
      </v-card-title>

      <v-dialog max-width="1200">
        <template #activator="{ props: activatorProps }">
          <v-btn
            v-bind="activatorProps"
            variant="text"
          >
            <v-icon icon="mdi-information" />
          </v-btn>
        </template>
        <template #default="{ isActive }">
          <v-card class="position-relative">
            <div
              class="pa-4 d-flex flex-row justify-space-between position-sticky top-0 w-100"
              :style="`background-color: ${theme.current.value.colors.surface}`"
            >
              <v-card-title>
                {{ artifactTitle }}
              </v-card-title>

              <v-btn
                icon="mdi-close"
                variant="text"
                @click="isActive.value = false"
              />
            </div>
            <v-card-text class="px-4 pt-0 pb-6">
              <div>
                <pre
                  style="overflow-x: auto"
                ><code>{{ JSON.stringify(artifact, null, 2) }}</code></pre>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </div>
    <v-card-text class="pt-0">
      <!-- properties -->
      <div :class="isBelow1200px ? 'd-flex flex-column align-start' : 'd-flex flex-row align-start'">
        <v-list
          :class="isBelow1200px ? 'w-100' : ''"
          density="compact" 
        >
          <v-list-item
            :title="t('artifact.properties.status')"
            :subtitle="t('artifact.status.' + artifact.status)"
            prepend-icon="mdi-information"
          />
          <v-list-item
            v-if="artifact.data && artifact.data.cameraId"
            :title="t('artifact.properties.cameraId')"
            :subtitle="useCameraName(artifact.data.cameraId)"
            prepend-icon="mdi-video"
          />
          <v-list-item
            v-if="artifact.jobName"
            :title="t('artifact.properties.jobName')"
            :subtitle="artifact.jobName"
            prepend-icon="mdi-note-check"
          />
          <v-list-item
            v-if="hasDuration"
            :title="t('artifact.properties.time')"
            :subtitle="humanReadableTime"
            prepend-icon="mdi-timelapse"
          />
          <v-list-item
            v-if="artifact && artifact.data && artifact.data.frameIntervalSeconds"
            :title="t('artifact.properties.frameInterval')"
            :subtitle="
              humanReadableDuration(artifact.data.frameIntervalSeconds * 1000)
            "
            prepend-icon="mdi-timer"
          />
          <v-list-item
            v-if="artifact && artifact.size"
            :title="t('artifact.properties.filesize')"
            :subtitle="humanReadableFilesize(artifact.size)"
            prepend-icon="mdi-harddisk"
          />
          <v-list-item
            v-if="artifact.artifactId"
            prepend-icon="mdi-identifier"
            append-icon="mdi-content-copy"
            :title="$t('artifact.properties.id')"
            :subtitle="artifact.artifactId"
            @click="copyComponentId"
          />
        </v-list>

        <!-- image -->
        <v-card
          :class="isBelow1200px ? 'w-100' : 'flex-fill'"
          variant="flat"
          elevation="0"
        >
          <v-card-text>
            <ArtifactPayload :artifact-id="artifact.artifactId" />
          </v-card-text>
          <v-card-actions class="justify-space-between">
            <div>
              <v-btn
                v-if="
                  !isDownloaded &&
                    (artifact?.status === 'done' ||
                      artifact?.status === 'archived')
                "
                prepend-icon="mdi-download"
                :disabled="isDownloading"
                @click="downloadPayload"
              >
                {{ $t("general_interface.buttons.download") }}
              </v-btn>
              <v-btn
                v-if="isDownloaded"
                prepend-icon="mdi-content-save-outline"
                @click="savePayload"
              >
                {{ $t("general_interface.buttons.save") }}
              </v-btn>
              <v-btn
                v-if="
                  !isDownloaded &&
                    (artifact?.status === 'done' ||
                      artifact?.status === 'archived')
                "
                prepend-icon="mdi-content-save-outline"
                :disabled="isDownloading"
                @click="downloadAndSavePayload"
              >
                {{ $t("general_interface.buttons.download_and_save") }}
              </v-btn>
              <ShareButton
                :url="shareUrl"
                :text="$t('general_interface.buttons.share')"
              />
            </div>
            <v-btn
              v-if="isDownloaded"
              prepend-icon="mdi-fullscreen"
            >
              {{ $t("general_interface.buttons.fullscreen") }}
              <v-dialog
                v-model="fullscreenPayload"
                :fullscreen="true"
                activator="parent"
              >
                <v-card>
                  <v-card-title>
                    {{ artifactTitle }}
                  </v-card-title>
                  <v-card-text>
                    <v-container class="fill-height">
                      <ArtifactPayload
                        v-if="fullscreenPayload"
                        :artifact-id="artifact.artifactId"
                        :full-screen="true"
                      />
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                      v-if="isDownloaded"
                      @click="savePayload"
                    >
                      {{ $t("general_interface.buttons.save") }}
                    </v-btn>
                    <v-btn @click="fullscreenPayload = false">
                      {{ $t("general_interface.buttons.close") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-card-text>
  </v-layout-card>
</template>
