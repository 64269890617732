<script setup>
import NightvisionTimelineChart from '@/components/charts/NightvisionTimelineChart.vue';
import LabelCountsLineChart from '@/components/charts/LabelCountsLineChart.vue';
import SimpleLineChart from '@/components/charts/SimpleLineChart.vue';
import RegionChart from '@/components/charts/RegionChart.vue';
import { computed, onMounted, ref } from 'vue';
import ChartTimeSetupPicker from '@/components/charts/ChartTimeSetupPicker.vue';
import { useInstanceStore } from '@/store/backendInstances.js';
import GridScoreDerivativeChart from '@/components/charts/GridScoreDerivativeChart.vue';
import { useRoute, useRouter } from 'vue-router';
import { DateTime } from 'luxon';

const instanceStore = useInstanceStore();
const router = useRouter();
const route = useRoute();

const chainedTimeSetup = ref(null);
const chainTimeSetup = ref(true);
const startTime = ref(null);
const endTime = ref(null);

const canSeeDistributionScores = computed(() => {
  return instanceStore.hasAnyPermission([
    'data.view',
    'data.view.distribution',
  ]);
});

function updateChainedTimeSetup(timeSetup) {
  if (chainTimeSetup.value) chainedTimeSetup.value = timeSetup;
  router.replace({
    query: {
      start: timeSetup.start,
      end: timeSetup.end,
    },
  })
}

const setStartTime = () => {
  if (route.query.start || !isNaN(parseInt(route.query.start))) {
    return startTime.value = DateTime.fromMillis(parseInt(route.query.start));
  }
  return startTime.value = DateTime.now().minus({ days: 4 }).startOf('second');
}

const setEndTime = () => {
  if (route.query.end || !isNaN(parseInt(route.query.end))) {
    return endTime.value = DateTime.fromMillis(parseInt(route.query.end));
  }
  return endTime.value = DateTime.now().startOf('second');
}

onMounted(() => {
  setStartTime();
  setEndTime();
});
</script>

<template>
  <v-container
    :fluid="true"
    class="ma-0 pa-0"
  >
    <v-layout-card>
      <v-card-title>
        {{ $t("measurements.titles.barn_measurements") }}
      </v-card-title>
      <v-card-text>
        <v-row
          justify="center"
        >
          <v-col :cols="12">
            <v-row class="justify-end">
              <v-col cols="auto">
                <v-switch
                  v-model="chainTimeSetup"
                  :label="$t('charts.chain_time_setup')"
                />
              </v-col>
              <v-col
                cols="auto"
              >
                <ChartTimeSetupPicker
                  v-if="chainTimeSetup && startTime && endTime"
                  :model-value="chainedTimeSetup"
                  :hide-time-resolution="true"
                  :start-time="startTime"
                  :end-time="endTime"
                  @update:model-value="updateChainedTimeSetup($event)"
                />
              </v-col>
            </v-row>
          </v-col>

          <div 
            v-if="chainedTimeSetup"
            class="w-100"
          >
            <v-col :cols="12">
              <SimpleLineChart
                :aggregate="true"
                measurement="activity"
                :subtitle="$t('charts.subtitles.activityGlobal')"
                field="activity"
                :title="$t('charts.titles.activity')"
                :time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
              />
            </v-col>

            <v-col :cols="12">
              <SimpleLineChart
                :aggregate="true"
                measurement="speed"
                field="speed"
                :subtitle="$t('charts.subtitles.speedGlobal')"
                :title="$t('charts.titles.speed')"
                :time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
              />
            </v-col>

            <v-col
              v-if="canSeeDistributionScores"
              :cols="12"
            >
              <SimpleLineChart
                :aggregate="false"
                measurement="distribution"
                field="gridScore"
                :subtitle="$t('charts.subtitles.gridScore')"
                :title="$t('charts.titles.gridScore')"
                :time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
              />
            </v-col>

            <v-col
              v-if="canSeeDistributionScores"
              :cols="12"
            >
              <GridScoreDerivativeChart
                v-model:time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
                :title="$t('charts.titles.gridScoreSpread')"
                :subtitle="$t('charts.subtitles.gridScoreSpread')"
                :aggregate-cameras="true"
              />
            </v-col>

            <v-col
              v-if="canSeeDistributionScores"
              :cols="12"
            >
              <SimpleLineChart
                :aggregate="false"
                measurement="distribution"
                field="spatialDisparity"
                :subtitle="$t('charts.subtitles.spatialDisparity')"
                :title="$t('charts.titles.spatialDisparity')"
                :time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
              />
            </v-col>

            <v-col :cols="12">
              <RegionChart
                :aggregate-labels="false"
                :region-type="'stalltechnik'"
                :title="$t('charts.titles.regions')"
                :subtitle="$t('charts.subtitles.regionsBarnEquipment')"
                :time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
              />
            </v-col>

            <v-col :cols="12">
              <NightvisionTimelineChart
                :default-collapsed="true"
                :aggregate="false"
                :time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
                :title="$t('charts.titles.nightvision_range_bar')"
              />
            </v-col>

            <v-col :cols="12">
              <SimpleLineChart
                :aggregate="false"
                measurement="activity"
                :subtitle="$t('charts.subtitles.activityPerCamera')"
                field="activity"
                :title="$t('charts.titles.activity')"
                :time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
              />
            </v-col>

            <v-col :cols="12">
              <SimpleLineChart
                :aggregate="false"
                measurement="speed"
                field="speed"
                :subtitle="$t('charts.subtitles.speedPerCamera')"
                :title="$t('charts.titles.speed')"
                :time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
              />
            </v-col>

            <v-col :cols="12">
              <RegionChart
                :aggregate-labels="false"
                :region-type="'stallbereich_vh'"
                :title="$t('charts.titles.regions')"
                :subtitle="$t('charts.subtitles.regionsFrontBack')"
                :time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
                :height="600"
              />
            </v-col>

            <v-col :cols="12">
              <RegionChart
                :aggregate-labels="false"
                :region-type="'stallbereich_lr'"
                :title="$t('charts.titles.regions')"
                :subtitle="$t('charts.subtitles.regionsLeftRight')"
                :time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
                :height="600"
              />
            </v-col>

            <v-col :cols="12">
              <LabelCountsLineChart
                :aggregate-cameras="true"
                :aggregate-labels="false"
                :title="$t('charts.titles.labelCounts')"
                :subtitle="$t('charts.subtitles.labelCountsGlobal')"
                :time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
                :height="400"
              />
            </v-col>

            <v-col :cols="12">
              <LabelCountsLineChart
                :aggregate-cameras="false"
                :aggregate-labels="true"
                :title="$t('charts.titles.labelCounts')"
                :subtitle="$t('charts.subtitles.labelCountsPerCamera')"
                :time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
                :height="600"
              />
            </v-col>

            <v-col :cols="12">
              <RegionChart
                :aggregate-labels="true"
                :region-type="'pen'"
                :specified-labels="[
                  'piglet',
                  'piglet_lying',
                  'piglet_standing',
                  'piglet_sitting',
                ]"
                :title="$t('charts.titles.regions')"
                :time-setup="chainedTimeSetup"
                :chain-time-setup="chainTimeSetup"
                :subtitle="$t('charts.subtitles.regionsPen')"
                :height="600"
              />
            </v-col>
          </div>
        </v-row>
      </v-card-text>
    </v-layout-card>
  </v-container>
</template>

<style scoped></style>
